// ACTION TYPES
export const AUTH_USER_DISCOVERED = 'AUTH_USER_DISCOVERED';

// ACTION CREATORS
export function setUserDiscovered(payload) {
  return { type: AUTH_USER_DISCOVERED, payload };
}

// INITIAL STATE
const initialState = {
  jwt: null,
  user: null,
  roles: []
};

export default (state = initialState, action) => {
  let user;
  switch (action.type) {
    case AUTH_USER_DISCOVERED:
      user = action.payload.decodedJWT;
      return {
        ...state,
        jwt: action.payload.jwt,
        user,
        roles: user.realm_access.roles
      };
    default:
      return state;
  }
};
