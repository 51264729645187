import React from 'react';
import { provideHooks } from 'redial';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { matchRoutes } from 'react-router-config';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import routes from 'routes';
import bookReducer, * as bookActions from 'redux/modules/book';
import { openDrawer } from 'redux/modules/app';
import Breadcrumb from '../Breadcrumb/Breadcrumb';
import MenuIcon from '../../../node_modules/@material-ui/icons/Menu';

const styles = theme => ({
  breadcrumb: {
    '& > a': {
      color: 'white'
    }
  },
  menuButton: {
    marginLeft: -theme.spacing.unit,
    marginRight: theme.spacing.unit
  },
  hide: {
    display: 'none'
  }
});

@provideHooks({
  fetch: async ({ store: { dispatch, getState, inject }, params: { categoryShortName, bookName } }) => {
    inject({ bookReducer });

    const state = getState();

    if (state.online && categoryShortName && bookName) {
      await dispatch(bookActions.getBook(categoryShortName, bookName));
    }
  }
})
@connect(
  state => ({
    drawerIsOpen: state.app.drawerOpen,
    bookColor: state.bookReducer && state.bookReducer.book.metadata && state.bookReducer.book.metadata.color
  }),
  { mappedOpenDrawer: openDrawer }
)
class Navbar extends React.Component {
  static propTypes = {
    classes: PropTypes.objectOf(PropTypes.string).isRequired,
    drawerIsOpen: PropTypes.bool.isRequired,
    bookColor: PropTypes.string,
    location: PropTypes.shape({ pathname: PropTypes.string }).isRequired,
    mappedOpenDrawer: PropTypes.func.isRequired
  };

  static defaultProps = {
    bookColor: '#1f3368'
  };

  handleDrawerOpen = () => {
    const { mappedOpenDrawer } = this.props;
    mappedOpenDrawer();
  };

  render() {
    const {
      classes, bookColor, location, drawerIsOpen
    } = this.props;
    const routerInfo = matchRoutes(routes, location.pathname).find(r => r.match.isExact);
    const imgBannerLarge = require('./../../../static/banner-graphic.png');
    const imgBannerSmall = require('./../../../static/banner-small.png');
    const bannerStyleLarge = { height: '180px', marginTop: '-40px', marginLeft: 'auto' };
    const appBarStyle = bookColor ? { backgroundColor: bookColor } : null;
    const menuButtonRequired = routerInfo ? !!routerInfo.match.params.problemName : false;
    return (
      <React.Fragment>
        <div className="container">
          <img className="visible-lg-inline" src={imgBannerLarge} style={bannerStyleLarge} alt="CPM Homework Banner" />
          <img className="hidden-lg" src={imgBannerSmall} alt="" />
        </div>

        <AppBar position="sticky" style={appBarStyle}>
          <Toolbar>
            {menuButtonRequired && (
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                className={classes.menuButton}
                disabled={drawerIsOpen}
              >
                <MenuIcon />
              </IconButton>
            )}

            <Breadcrumb className={classes.breadcrumb} routerInfo={routerInfo} />
          </Toolbar>
        </AppBar>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(withRouter(Navbar));
