const environment = {
  development: {
    isProduction: false,
    assetsPath: `http://${process.env.HOST || 'localhost'}:${+process.env.PORT + 1 || 3001}/dist/`
  },
  production: {
    isProduction: true,
    assetsPath: '/dist/'
  }
}[process.env.NODE_ENV || 'development'];

const config = {
  host: process.env.HOST || 'localhost',
  port: process.env.PORT,
  apiHost: process.env.APIHOST || 'localhost',
  apiPort: process.env.APIPORT,
  app: {
    title: 'CPM Homework Help',
    description: 'CPM Education Program proudly works to offer more and better math education to more students.',
    head: {
      titleTemplate: 'CPM Homework Help : %s',
      meta: [
        { name: 'description', content: 'Bringing more math to more students.' },
        { charset: 'utf-8' },
        { property: 'og:site_name', content: 'CPM Homework Help' },
        { property: 'og:image', content: 'https://sso.cpm.org/images/cpm-logo.png' },
        { property: 'og:locale', content: 'en_US' },
        { property: 'og:title', content: 'CPM Homework Help' },
        {
          property: 'og:description',
          content: 'CPM Education Program proudly works to offer more and better math education to more students.'
        },
        { property: 'og:card', content: 'summary' },
        { property: 'og:site', content: 'https://homework.cpm.org' },
        { property: 'og:creator', content: 'technology@cpm.org' },
        { property: 'og:image:width', content: '200' },
        { property: 'og:image:height', content: '200' }
      ]
    }
  },
  c3poAPI: {
    debug: true,
    warn: true,
    server: process.env.API_SERVER || 'https://c3po-api.cpm.org'
  },
  speechStream: {
    enabled: process.env.SPEECHSTREAM_ENABLED === 'true' || false
  },
  layout: {
    drawerWidth: 300
  }
};

Object.assign(config, environment);

export default config;
