import React, { Component } from 'react';
// import PropTypes from 'prop-types';
// import { Link } from 'git react-router-dom';
// import { CounterButton, GithubButton } from 'components';
// import config from 'config';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';

@connect(state => ({
  online: state.online
}))
class Home extends Component {
  static propTypes = {
    // online: PropTypes.bool.isRequired
  };

  render() {
    // const { online } = this.props;
    const styles = require('./Home.scss');
    // require the logo image both from client and server
    // const logoImage = require('./logo.png');
    return (
      <div className={styles.home}>
        <Helmet title="Home" />
        <h1>Welcome To CPM Homework Help Categories Coming Below Soon</h1>
      </div>
    );
  }
}

export default Home;
