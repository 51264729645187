// ACTION TYPES
export const APP_OPEN_DRAWER = 'APP_OPEN_DRAWER';
export const APP_CLOSE_DRAWER = 'APP_CLOSE_DRAWER';

// ACTION CREATORS
export function openDrawer() {
  return { type: APP_OPEN_DRAWER };
}
export function closeDrawer() {
  return { type: APP_CLOSE_DRAWER };
}

// INITIAL STATE
const initialState = {
  drawerOpen: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case APP_OPEN_DRAWER:
      return { ...state, drawerOpen: true };
    case APP_CLOSE_DRAWER:
      return { ...state, drawerOpen: false };
    default:
      return state;
  }
};
