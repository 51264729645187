'use strict';

var DEFAULT_URI_ENDPOINT = '';
var pagedRequestHandler = require('./pagedRequestHandler');

var Client;
Client = module.exports = function(params){

  if(!params) params = {};

  /*
   SUPPORTED PARAMS:
   server - Optional, defaults to blah

   debug - Defaults to false
   version - Defaults to v1
   */

  this.server = (params.server) ? params.server : DEFAULT_URI_ENDPOINT;

  if(this.server.length === 0 &&  (typeof window !== 'undefined')) {
    this.server = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '');
  };

  this.debug = (params.debug) ? params.debug : false;
  this.warn = (params.warn) ? params.warn : false;

  this.warn = (params.warn) ? params.warn : false;
  this.version = (params.version) ? params.version : 'v1';
  this.token = params.token;
  this.disableQueryParams = (params.disableQueryParams) ? params.disableQueryParams : false;

  this.useAgent = (params.useAgent === true) ? params.useAgent : false;

  this.ssoToken = params.ssoToken;
  this.apiToken = params.apiToken;

  return this;
};


Client.prototype.pagedGetRequest = function(path, useRawPath){

  var uri = this.generateUriFromPath.call(this, path, useRawPath);

  var handler = new pagedRequestHandler(uri, {
    debug: this.debug,
    verb: 'GET',
    useAgent: this.useAgent,
    disableQueryParams: this.disableQueryParams,
    token: this.token,
    ssoToken: this.ssoToken,
    apiToken: this.apiToken
  });

  return handler;

}

Client.prototype.pagedRequest = function(verb, path, payload){

  var uri = this.generateUriFromPath.call(this, path);

  var pageParams = {
    debug: this.debug,
    verb: verb,
    useAgent: this.useAgent,
    disableQueryParams: this.disableQueryParams,
    token: this.token,
    ssoToken: this.ssoToken,
    apiToken: this.apiToken
  };

  if(payload){
    pageParams.payload = payload;
  }

  return new pagedRequestHandler(uri, pageParams);
}

Client.prototype.generateUriFromPath = function(path, useRawPath){


  var uri;

  if(!useRawPath || useRawPath === false){
    uri = this.server + '/api/' + this.version  + path ;
  }
  else{
    uri = this.server + path ;
  }

  if(this.testSubdomain){
    uri = uri + '?cname=' + this.testSubdomain;
  }

  return uri;

};


// Classifications
Client.prototype.getClassifications = function(){
  return this.pagedGetRequest('/classifications');
};

Client.prototype.createClassification = function(classificationInfo){
  return this.pagedRequest('POST', '/classifications', classificationInfo);
};

Client.prototype.getClassification = function(classificationId){
  return this.pagedGetRequest('/classifications/' + classificationId);
};

Client.prototype.updateClassification = function(classificationInfo){
  return this.pagedRequest('PUT', '/classifications/' + classificationInfo.id, classificationInfo);
};

Client.prototype.deleteClassification = function(classificationId){
  return this.pagedRequest('DELETE', '/classifications/' + classificationId);
};

Client.prototype.getGradesForClassification = function(classificationId){
  return this.pagedGetRequest(`/classifications/${classificationId}/grades`)
};

Client.prototype.createGradeForClassification = function(classificationId, gradeInfo){
  return this.pagedRequest('POST', `/classifications/${classificationId}/grades`, gradeInfo)
};

Client.prototype.setClassificationOrder = function (classificationIds) {
  return this.pagedRequest('PUT', '/classifications/order', classificationIds);
};

Client.prototype.setClassificationGradeOrder = function (classificationId, gradeIds) {
  return this.pagedRequest('PUT', '/classifications/' + classificationId + '/gradeOrder', gradeIds);
};

// Grades
Client.prototype.getGrades = function(){
  return this.pagedGetRequest('/grades');
};

Client.prototype.createGrade = function(gradeInfo){
  return this.pagedRequest('POST', '/grades', gradeInfo);
};

Client.prototype.getGrade = function(gradeId){
  return this.pagedGetRequest('/grades/' + gradeId);
};

Client.prototype.updateGrade = function(gradeInfo){
  return this.pagedRequest('PUT', '/grades/' + gradeInfo.id, gradeInfo);
};

Client.prototype.deleteGrade = function(gradeId){
  return this.pagedRequest('DELETE', '/grades/' + gradeId);
};

Client.prototype.getClassificationStandardForGrade = function(gradeId){
  return this.pagedGetRequest(`/grades/${gradeId}/classificationstandards`)
};

Client.prototype.createClassificationStandardForGrade = function(gradeId, classificationStandardInfo){
  return this.pagedRequest('POST', `/grades/${gradeId}/classificationstandards`, classificationStandardInfo)
};

// ClassificationStandards

Client.prototype.getStandardsForClassification = function(classificationId){
  return this.pagedGetRequest('/classifications/' + classificationId + '/classificationstandards');
};

Client.prototype.getClassificationStandards = function(){
  return this.pagedGetRequest('/classificationstandards');
};

Client.prototype.createStandardForClassification = function(classificationId, classificationStandardInfo){
  return this.pagedRequest('POST', '/classifications/' + classificationId + '/classificationstandards', classificationStandardInfo);
};

Client.prototype.removeStandardFromClassification = function(classificationId, classificationStandardId){
  return this.pagedRequest('DELETE', '/classifications/' + classificationId + '/classificationstandards/'+ classificationStandardId);
};

Client.prototype.getClassificationStandard = function(classificationStandardId){
  return this.pagedGetRequest('/classificationstandards/' + classificationStandardId);
};

Client.prototype.mapContentRelationsForClassificationStandard = function(classificationStandardId, payload){
  return this.pagedRequest('POST', '/classificationstandards/' + classificationStandardId + '/map', payload);
};

Client.prototype.getClassificationStandardsLessons = function(classificationStandardId) {
  return this.pagedGetRequest('/classificationstandards/' + classificationStandardId + '/lessons');
};

Client.prototype.getClassificationStandardsProblems = function(classificationStandardId) {
  return this.pagedGetRequest('/classificationstandards/' + classificationStandardId + '/problems');
};

Client.prototype.updateClassificationStandard = function(classificationStandardInfo){
  return this.pagedRequest('PUT', '/classificationstandards/' + classificationStandardInfo.id, classificationStandardInfo);
};

Client.prototype.deleteClassificationStandard = function(classificationStandardId){
  return this.pagedRequest('DELETE', '/classificationstandards/' + classificationStandardId);
};

// Quality Control Regulations
Client.prototype.getQualityControlRegulations = function() {
  return this.pagedGetRequest('/qualitycontrolregulations');
};

Client.prototype.createQualityControlRegulation = function(qualityControlRegulationInfo) {
  return this.pagedRequest('POST', '/qualitycontrolregulations', qualityControlRegulationInfo);
};

Client.prototype.getQualityControlRegulation = function(qualityControlRegulationId) {
  return this.pagedGetRequest('/qualitycontrolregulations/' + qualityControlRegulationId);
};

Client.prototype.updateQualityControlRegulation = function(qualityControlRegulationInfo) {
  return this.pagedRequest('PUT', '/qualitycontrolregulations/' + qualityControlRegulationInfo.id, qualityControlRegulationInfo);
};

Client.prototype.deleteQualityControlRegulation = function(qualityControlRegulationId) {
  return this.pagedRequest('DELETE', '/qualitycontrolregulations/' + qualityControlRegulationId);
};

// Control Requirements
Client.prototype.getControlRequirements = function(){
  return this.pagedGetRequest('/controlrequirements');
};

Client.prototype.getRequirementsForQualityControlRegulation = function(qualityControlRegulationId) {
  return this.pagedGetRequest('/qualitycontrolregulations/' + qualityControlRegulationId + '/controlrequirements');
};

Client.prototype.createRequirementForQualityControlRegulation = function(qualityControlRegulationId, controlRequirementInfo){
  return this.pagedRequest('POST', '/qualitycontrolregulations/' + qualityControlRegulationId + '/controlrequirements', controlRequirementInfo);
};

Client.prototype.getControlRequirement = function(controlRequirementId){
  return this.pagedGetRequest('/controlrequirements/' + controlRequirementId);
};

Client.prototype.updateControlRequirement = function(controlRequirementInfo){
  return this.pagedRequest('PUT', '/controlrequirements/' + controlRequirementInfo.id, controlRequirementInfo);
};

Client.prototype.deleteControlRequirement = function(controlRequirementId){
  return this.pagedRequest('DELETE', '/controlrequirements/' + controlRequirementId);
};

// Categories
Client.prototype.getCategories = function(){
    return this.pagedGetRequest('/categories');
};

Client.prototype.createCategory = function(categoryInfo){
    return this.pagedRequest('POST', '/categories', categoryInfo);
};

Client.prototype.getCategory = function(categoryId){
    return this.pagedGetRequest('/categories/' + categoryId);
};

Client.prototype.updateCategory = function(categoryInfo){
    return this.pagedRequest('PUT', '/categories/' + categoryInfo.id, categoryInfo);
};

Client.prototype.deleteCategory = function(categgoryId){
    return this.pagedRequest('DELETE', '/categories/' + categgoryId);
};

Client.prototype.getCategoryBooks = function(categoryId){
    return this.pagedGetRequest('/categories/' + categoryId + '/books');
};

Client.prototype.addBookToCategory = function(categoryId, bookId){
    return this.pagedRequest('PUT', '/categories/' + categoryId + '/books/' + bookId);
};

Client.prototype.removeBookFromCategory = function(categoryId, bookId){
    return this.pagedRequest('DELETE', '/categories/' + categoryId + '/books/' + bookId);
};

Client.prototype.setCategoryOrder = function (categoryIds) {
  return this.pagedRequest('PUT', '/categories/order', categoryIds);
};

Client.prototype.setCategoryBookOrder = function (categoryId, bookIds) {
  return this.pagedRequest('PUT', '/categories/' + categoryId + '/bookOrder', bookIds);
};

// Books
Client.prototype.getBooks = function(){
  return this.pagedGetRequest('/books');
};

Client.prototype.createBook = function(bookInfo){
  return this.pagedRequest('POST', '/books', bookInfo);
};

Client.prototype.getBook = function(bookId){
  return this.pagedGetRequest('/books/' + bookId);
};

Client.prototype.getMergeReportForBook = function(bookId){
  return this.pagedGetRequest('/books/' + bookId + '/mergereport');
};

Client.prototype.updateBook = function(bookInfo){
  return this.pagedRequest('PUT', '/books/' + bookInfo.id, bookInfo);
};

Client.prototype.deleteBook = function(bookId){
  return this.pagedRequest('DELETE', '/books/' + bookId);
};

Client.prototype.getBookChapters = function(bookId){
  return this.pagedGetRequest('/books/' + bookId + '/chapters');
};

Client.prototype.addChapterToBook = function(bookId, chapterId){
  return this.pagedRequest('PUT', '/books/' + bookId + '/chapters/' + chapterId);
};

Client.prototype.setBookChapterOrder = function (bookId, chapterIds) {
  return this.pagedRequest('PUT', '/books/' + bookId + '/chapterOrder', chapterIds);
};

Client.prototype.getBookSections = function(bookId){
  return this.pagedGetRequest('/books/' + bookId + '/sections');
};

Client.prototype.addSectionToBook = function(bookId, sectionId){
  return this.pagedRequest('PUT', '/books/' + bookId + '/sections/' + sectionId);
};

Client.prototype.setBookSectionOrder = function (bookId, sectionIds) {
  return this.pagedRequest('PUT', '/books/' + bookId + '/sectionOrder', sectionIds);
};

Client.prototype.getBookChildren = function(bookId){
  return this.pagedGetRequest('/books/' + bookId + '/children');
};

Client.prototype.setBookChildOrder = function (bookId, children) {
  return this.pagedRequest('PUT', '/books/' + bookId + '/childOrder', children);
};

Client.prototype.getBookGlossaryItems = function(bookId){
  return this.pagedGetRequest('/books/' + bookId + '/glossaryItems');
};

Client.prototype.createBookGlossaryItem = function(bookId, glossaryItemInfo){
  return this.pagedRequest('POST', '/books/' + bookId + '/glossaryItems', glossaryItemInfo);
};

Client.prototype.addGlossaryItemToBook = function(bookId, glossaryItemId){
  return this.pagedRequest('PUT', '/books/' + bookId + '/glossaryItems/' + glossaryItemId);
};

Client.prototype.removeGlossaryItemFromBook = function(bookId, glossaryItemId){
  return this.pagedRequest('DELETE', '/books/' + bookId + '/glossaryItems/' + glossaryItemId);
};

Client.prototype.getBookMenus = function(bookId){
  return this.pagedGetRequest('/books/' + bookId + '/menus');
};

Client.prototype.createBookMenu = function(bookId, menuInfo){
  return this.pagedRequest('POST', '/books/' + bookId + '/menus', menuInfo);
};

Client.prototype.getBookLessons = function(bookId){
  return this.pagedGetRequest('/books/' + bookId + '/lessons');
};

Client.prototype.getBookSectionElements = function(bookId){
  return this.pagedGetRequest('/books/' + bookId + '/sections/elements');
};

Client.prototype.updateBookStyleSheet = function(bookId, styleInfo){
  return this.pagedRequest('PUT', '/books/' + bookId + '/stylesheet', styleInfo);
};

Client.prototype.rollbackBookStyleSheet = function(bookId){
  return this.pagedRequest('PUT', '/books/' + bookId + '/stylesheet/rollback');
};

Client.prototype.getBookCoverMedia = function(bookId){
  return this.pagedGetRequest('/books/' + bookId + '/media/cover');
};

Client.prototype.getBookByEbooksKey = function (ebooksKey) {
  return this.pagedGetRequest('/books/ebooksKey/' + ebooksKey);
};


// Assessment Thread

Client.prototype.getAssessmentGroup = function(assessmentGroupId){
  return this.pagedGetRequest('/assessmentGroups/' + assessmentGroupId);
};

Client.prototype.updateAssessmentGroup = function(assessmentGroupInfo){
  return this.pagedRequest('PUT', '/assessmentGroups/' + assessmentGroupInfo.id, assessmentGroupInfo);
};

Client.prototype.deleteAssessmentGroup = function(assessmentGroupId){
  return this.pagedRequest('DELETE', '/assessmentGroups/' + assessmentGroupId);
};

Client.prototype.getAssessmentProblemsForAssessmentGroup = function(assessmentGroupId){
  return this.pagedGetRequest(`/assessmentGroups/${assessmentGroupId}/assessmentproblems`)
};

Client.prototype.createAssessmentProblemForAssessmentGroup = function(assessmentGroupId, assessmentProblemInfo){
  return this.pagedRequest('POST', `/assessmentGroups/${assessmentGroupId}/assessmentproblems`, assessmentProblemInfo)
};

Client.prototype.addAssessmentProblemToAssessmentGroup = function(assessmentGroupId, assessmentProblemId){
  return this.pagedRequest('PUT', `/assessmentGroups/${assessmentGroupId}/assessmentproblems/${assessmentProblemId}`)
};

Client.prototype.removeAssessmentProblemFromAssessmentGroup = function(assessmentGroupId, assessmentProblemId){
  return this.pagedRequest('DELETE', `/assessmentGroups/${assessmentGroupId}/assessmentproblems/${assessmentProblemId}`)
};

Client.prototype.setAssesssmentProblemOrderForAssessmentGroup = function(assessmentGroupId, idArray){
  return this.pagedRequest('PUT', `/assessmentGroups/${assessmentGroupId}/assessmentproblems/order`, idArray)
};

// Menus
Client.prototype.createMenu = function(menuInfo){
  return this.pagedRequest('POST', '/menus', menuInfo);
};

Client.prototype.getMenu = function(menuId){
  return this.pagedGetRequest('/menus/' + menuId);
};

Client.prototype.renderMenu = function(menuId){
  return this.pagedGetRequest('/menus/' + menuId + '/render');
};

Client.prototype.updateMenu = function(menuInfo){
  return this.pagedRequest('PUT', '/menus/' + menuInfo.id, menuInfo);
};

Client.prototype.deleteMenu = function(menuId){
  return this.pagedRequest('DELETE', '/menus/' + menuId);
};

Client.prototype.createMenuItemForMenu = function(menuId, menuItemInfo){
  return this.pagedRequest('POST', `/menus/${menuId}/menuitems`, menuItemInfo);
};

Client.prototype.getMenuItemsForMenu = function(menuId){
  return this.pagedGetRequest(`/menus/${menuId}/menuitems`);
};

Client.prototype.addMenuItemToMenu = function(menuId, menuItemId){
  return this.pagedRequest('PUT', `/menus/${menuId}/menuitems/${menuItemId}`);
};

Client.prototype.removeMenuItemFromMenu = function(menuId, menuItemId){
  return this.pagedRequest('DELETE', `/menus/${menuId}/menuitems/${menuItemId}`);
};

Client.prototype.getElementsForMenu = function(menuId){
  return this.pagedGetRequest('/menus/' + menuId + '/elements');
};

// Menu Items
Client.prototype.createMenuItem = function(menuItemInfo){
  return this.pagedRequest('POST', '/menuitems', menuItemInfo);
};

Client.prototype.getMenuItem = function(menuItemId){
  return this.pagedGetRequest('/menuitems/' + menuItemId);
};

Client.prototype.updateMenuItem = function(menuItemInfo){
  return this.pagedRequest('PUT', '/menuitems/' + menuItemInfo.id, menuItemInfo);
};

Client.prototype.deleteMenuItem = function(menuItemId){
  return this.pagedRequest('DELETE', '/menuitems/' + menuItemId);
};

Client.prototype.createChildMenuItem = function(menuItemId, menuItemInfo){
  return this.pagedRequest('POST', `/menuItems/${menuItemId}/children`, menuItemInfo);
};

Client.prototype.getChildMenuItems = function(menuItemId){
  return this.pagedGetRequest(`/menuItems/${menuItemId}/children`);
};

Client.prototype.addChildMenuItem = function(menuItemId, childMenuItemId){
  return this.pagedRequest('PUT', `/menuItems/${menuItemId}/children/${childMenuItemId}`);
};

Client.prototype.removeChildMenuItem = function(menuItemId, childMenuItemId){
  return this.pagedRequest('DELETE', `/menuItems/${menuItemId}/children/${childMenuItemId}`);
};



// Chapters
Client.prototype.getChapters = function(){
  return this.pagedGetRequest('/chapters');
};

Client.prototype.createChapter = function(chapterInfo){
  return this.pagedRequest('POST', '/chapters', chapterInfo);
};

Client.prototype.getChapter = function(chapterId){
  return this.pagedGetRequest('/chapters/' + chapterId);
};

Client.prototype.updateChapter = function(chapterInfo){
  return this.pagedRequest('PUT', '/chapters/' + chapterInfo.id, chapterInfo);
};

Client.prototype.deleteChapter = function(chapterId){
  return this.pagedRequest('DELETE', '/chapters/' + chapterId);
};

Client.prototype.getChapterLessons = function(chapterId){
  return this.pagedGetRequest('/chapters/' +  chapterId + '/lessons');
};

Client.prototype.createChapterLesson = function(chapterId, lessonInfo){
  return this.pagedRequest('POST', '/chapters/' +  chapterId + '/lessons', lessonInfo);
};

Client.prototype.setChapterLessonOrder = function (chapterId, lessonIds) {
  return this.pagedRequest('PUT', '/chapters/' + chapterId + '/lessonOrder', lessonIds);
};

Client.prototype.getChapterAssessmentProblems = function(chapterId){
  return this.pagedGetRequest('/chapters/' +  chapterId + '/assessmentgroups');
};

Client.prototype.getChapterAssessmentGroups = function(chapterId){
  return this.pagedGetRequest('/chapters/' +  chapterId + '/assessmentgroups');
};

Client.prototype.createChapterElement = function(chapterId, elementInfo){
  return this.pagedRequest('POST', '/chapters/' +  chapterId + '/elements', elementInfo);
};

Client.prototype.getChapterElements = function(chapterId){
  return this.pagedGetRequest('/chapters/' +  chapterId + '/elements');
};

Client.prototype.addChapterElement = function(chapterId, elementId){
  return this.pagedRequest('PUT','/chapters/' +  chapterId + '/elements/' + elementId);
};

Client.prototype.removeChapterElement = function(chapterId, elementId){
  return this.pagedRequest('DELETE','/chapters/' +  chapterId + '/elements/' + elementId);
};

Client.prototype.addChapterAssessmentProblem = function(chapterId, assessmentProblemId){
  return this.pagedRequest('PUT','/chapters/' +  chapterId + '/assessmentproblems/' + assessmentProblemId);
};

Client.prototype.removeChapterAssessmentProblem = function(chapterId, assessmentProblemId){
  return this.pagedRequest('DELETE','/chapters/' +  chapterId + '/assessmentproblems/' + assessmentProblemId);
};

Client.prototype.createChapterAssessmentProblem = function(chapterId, assessmentProblemInfo){
  return this.pagedRequest('POST', '/chapters/' +  chapterId + '/assessmentproblems', assessmentProblemInfo);
};

Client.prototype.createChapterAssessmentGroup = function(chapterId, assessmentGroupInfo){
  return this.pagedRequest('POST', '/chapters/' +  chapterId + '/assessmentgroups', assessmentGroupInfo);
};

// Sections
Client.prototype.getSections = function(){
  return this.pagedGetRequest('/sections');
};

Client.prototype.createSection = function(sectionInfo){
  return this.pagedRequest('POST', '/sections', sectionInfo);
};

Client.prototype.mergeSectionElements = function(mergeInfo){
  return this.pagedRequest('PUT', '/sections/merge', mergeInfo);
};

Client.prototype.getSection = function(sectionId){
  return this.pagedGetRequest('/sections/' + sectionId);
};

Client.prototype.updateSection = function(sectionInfo){
  return this.pagedRequest('PUT', '/sections/' + sectionInfo.id, sectionInfo);
};

Client.prototype.deleteSection = function(sectionId){
  return this.pagedRequest('DELETE', '/sections/' + sectionId);
};

Client.prototype.getSectionTabbedViews = function(sectionId){
  return this.pagedGetRequest('/sections/' +  sectionId + '/tabbedview');
};

Client.prototype.createSectionTabbedView = function(sectionId, tabbedViewInfo){
  return this.pagedRequest('POST', '/sections/' +  sectionId + '/tabbedview', tabbedViewInfo);
};

Client.prototype.getSectionAssessmentProblems = function(sectionId){
  return this.pagedGetRequest('/sections/' +  sectionId + '/assessmentproblems');
};

// Client.prototype.createSectionAssessmentProblem = function(sectionId, assessmentProblemInfo){
//   return this.pagedRequest('POST', '/sections/' +  sectionId + '/assessmentproblems', assessmentProblemInfo);
// };

Client.prototype.createSectionElement = function(sectionId, elementInfo){
  return this.pagedRequest('POST', '/sections/' +  sectionId + '/elements', elementInfo);
};

Client.prototype.getSectionElements = function(sectionId){
  return this.pagedGetRequest('/sections/' +  sectionId + '/elements');
};

Client.prototype.addSectionElement = function(sectionId, elementId){
  return this.pagedRequest('PUT','/sections/' +  sectionId + '/elements/' + elementId);
};

Client.prototype.removeSectionElement = function(sectionId, elementId){
  return this.pagedRequest('DELETE','/sections/' +  sectionId + '/elements/' + elementId);
};

// Lessons
Client.prototype.getLessons = function(){
  return this.pagedGetRequest('/lessons');
};

Client.prototype.createLesson = function(lessonInfo){
  return this.pagedRequest('POST', '/lessons', lessonInfo);
};

Client.prototype.getLesson = function(lessonId){
  return this.pagedGetRequest('/lessons/' + lessonId);
};

Client.prototype.getMediaForLesson = function(lessonId){
  return this.pagedGetRequest('/lessons/' + lessonId + '/media');
};

Client.prototype.getMediaForLessonElement = function(lessonId, elementId){
  return this.pagedGetRequest(`/lessons/${lessonId}/elements/${elementId}/media`);
};

Client.prototype.updateLesson = function(lessonInfo){
  return this.pagedRequest('PUT', '/lessons/' + lessonInfo.id, lessonInfo);
};

Client.prototype.deleteLesson = function(lessonId){
  return this.pagedRequest('DELETE', '/lessons/' + lessonId);
};

Client.prototype.getLessonContentContainersForLesson = function(lessonId){
  return this.pagedGetRequest('/lessons/' +  lessonId + '/lessoncontentcontainers');
};

Client.prototype.getProblemsForLesson = function(lessonId){
  return this.pagedGetRequest('/lessons/' +  lessonId + '/problems');
};

Client.prototype.createLessonContentContainerForLesson = function(lessonId, containerInfo){
  return this.pagedRequest('POST', '/lessons/' +  lessonId + '/lessoncontentcontainers', containerInfo);
};

Client.prototype.setLessonContentContainerOrderForLesson = function (lessonId, containerIds) {
  return this.pagedRequest('PUT', '/lessons/' + lessonId + '/lessoncontentcontainers/order', containerIds);
};

Client.prototype.getLessonElements = function(lessonId){
  return this.pagedGetRequest('/lessons/' +  lessonId + '/elements');
};

Client.prototype.createLessonElement = function(lessonId, elementInfo){
  return this.pagedRequest('POST', '/lessons/' +  lessonId + '/elements', elementInfo);
};

Client.prototype.addLessonElement = function(lessonId, elementId){
  return this.pagedRequest('PUT','/lessons/' +  lessonId + '/elements/' + elementId);
};

Client.prototype.removeLessonElement = function(lessonId, elementId){
  return this.pagedRequest('DELETE','/lessons/' +  lessonId + '/elements/' + elementId);
};

Client.prototype.getAssociatedLessonsForLesson = function(lessonId) {
  return this.pagedGetRequest('/lessons/' + lessonId + '/associatedlessons');
};

Client.prototype.getAssociatedProblemsForLesson = function(lessonId) {
  return this.pagedGetRequest('/lessons/' + lessonId + '/associatedproblems');
};

// GlossaryItems
Client.prototype.getGlossaryItems = function(){
  return this.pagedGetRequest('/glossaryitems');
};

Client.prototype.createGlossaryItem = function(glossaryItemInfo){
  return this.pagedRequest('POST', '/glossaryitems', glossaryItemInfo);
};

Client.prototype.createGlossaryItemWithContent = function(glossaryItemInfo){
  return this.pagedRequest('POST', '/glossaryitems/content', glossaryItemInfo);
};

Client.prototype.mergeGlossaryItemElements = function(mergeInfo){
  return this.pagedRequest('PUT', '/glossaryitems/merge', mergeInfo);
};

Client.prototype.getGlossaryItem = function(glossaryItemId){
  return this.pagedGetRequest('/glossaryitems/' + glossaryItemId);
};

Client.prototype.updateGlossaryItem = function(glossaryItemInfo){
  return this.pagedRequest('PUT', '/glossaryitems/' + glossaryItemInfo.id, glossaryItemInfo);
};

Client.prototype.deleteGlossaryItem = function(glossaryItemId){
  return this.pagedRequest('DELETE', '/glossaryitems/' + glossaryItemId);
};

Client.prototype.getGlossaryItemElements = function(glossaryItemId){
  return this.pagedGetRequest('/glossaryitems/' +  glossaryItemId + '/elements');
};

Client.prototype.getGlossaryItemCurrentVersion = function(glossaryItemId){
  return this.pagedGetRequest('/glossaryitems/' + glossaryItemId + '/currentversion');
};

Client.prototype.createGlossaryItemElement = function(glossaryItemId, elementInfo){
  return this.pagedRequest('POST', '/glossaryitems/' +  glossaryItemId + '/elements', elementInfo);
};

Client.prototype.addGlossaryItemElement = function(glossaryItemId, elementId){
  return this.pagedRequest('PUT','/glossaryitems/' +  glossaryItemId + '/elements/' + elementId);
};

Client.prototype.removeGlossaryItemElement = function(glossaryItemId, elementId){
  return this.pagedRequest('DELETE','/glossaryitems/' +  glossaryItemId + '/elements/' + elementId);
};


// TabbedViews
Client.prototype.getTabbedViews = function(){
  return this.pagedGetRequest('/tabbedviews');
};

Client.prototype.createTabbedView = function(tabbedViewInfo){
  return this.pagedRequest('POST', '/tabbedviews', tabbedViewInfo);
};

Client.prototype.getTabbedView = function(tabbedViewId){
  return this.pagedGetRequest('/tabbedviews/' + tabbedViewId);
};

Client.prototype.updateTabbedView = function(tabbedViewInfo){
  return this.pagedRequest('PUT', '/tabbedviews/' + tabbedViewInfo.id, tabbedViewInfo);
};

Client.prototype.deleteTabbedView = function(tabbedViewId){
  return this.pagedRequest('DELETE', '/tabbedviews/' + tabbedViewId);
};

Client.prototype.getTabbedViewTabs = function(tabbedViewId){
  return this.pagedGetRequest('/tabbedviews/' +  tabbedViewId + '/tabs');
};

Client.prototype.createTabbedViewTab = function(tabbedViewId, tabInfo){
  return this.pagedRequest('POST', '/tabbedviews/' +  tabbedViewId + '/tabs', tabInfo);
};

Client.prototype.setTabbedViewTabOrder = function(tabbedViewId, tabIdArray){
  return this.pagedRequest('PUT', '/tabbedviews/' +  tabbedViewId + '/tabs/order', tabIdArray);
};

Client.prototype.addTabbedViewTab = function(tabbedViewId, tabId){
  return this.pagedRequest('PUT','/tabbedviews/' +  tabbedViewId + '/tabs/' + tabId);
};

Client.prototype.removeTabbedViewTab = function(tabbedViewId, tabId){
  return this.pagedRequest('DELETE','/tabbedviews/' +  tabbedViewId + '/tabs/' + tabId);
};

Client.prototype.setTabbedViewTabOrder = function (tabbedViewId, tabIds) {
  return this.pagedRequest('PUT', '/tabbedviews/' + tabbedViewId + '/tabs/order', tabIds);
};

// Tabs
Client.prototype.getTabs = function(){
  return this.pagedGetRequest('/tabs');
};

Client.prototype.getTab = function(tabId){
  return this.pagedGetRequest('/tabs/' + tabId);
};

Client.prototype.updateTab = function(tabInfo){
  return this.pagedRequest('PUT', '/tabs/' + tabInfo.id, tabInfo);
};

Client.prototype.mergeTabs = function(mergeSpec){
  return this.pagedRequest('PUT', '/tabs/merge/', mergeSpec);
};

Client.prototype.deleteTab = function(tabId){
  return this.pagedRequest('DELETE', '/tabs/' + tabId);
};

Client.prototype.getTabElements = function(tabId){
  return this.pagedGetRequest('/tabs/' +  tabId + '/elements');
};

Client.prototype.getTabCurrentVersion = function(tabId){
  return this.pagedGetRequest('/tabs/' + tabId + '/currentversion');
};

Client.prototype.createTabElement = function(tabId, elementInfo){
  return this.pagedRequest('POST', '/tabs/' +  tabId + '/elements', elementInfo);
};

Client.prototype.addTabElement = function(tabId, elementId){
  return this.pagedRequest('PUT','/tabs/' +  tabId + '/elements/' + elementId);
};

Client.prototype.removeTabElement = function(tabId, elementId){
  return this.pagedRequest('DELETE','/tabs/' +  tabId + '/elements/' + elementId);
};

//AssessmentProblems
Client.prototype.getAssessmentProblems = function(){
  return this.pagedGetRequest('/assessmentproblems');
};

Client.prototype.createAssessmentProblem = function(assessmentProblemInfo){
  return this.pagedRequest('POST', '/assessmentproblems', assessmentProblemInfo);
};

Client.prototype.getAssessmentProblem = function(assessmentProblemId){
  return this.pagedGetRequest('/assessmentProblems/' + assessmentProblemId);
};

Client.prototype.getAssessmentProblemWithCurrrentVersionByElementType = function(assessmentProblemId, elementType){
  return this.pagedGetRequest('/assessmentProblems/' + assessmentProblemId + '/currentversion/' + elementType);
};

Client.prototype.updateAssessmentProblem = function(assessmentProblemInfo){
  return this.pagedRequest('PUT', '/assessmentproblems/' + assessmentProblemInfo.id, assessmentProblemInfo);
};

Client.prototype.deleteAssessmentProblem = function(assessmentProblemId){
  return this.pagedRequest('DELETE', '/assessmentproblems/' + assessmentProblemId);
};

Client.prototype.getAssessmentProblemElements = function(assessmentProblemId){
  return this.pagedGetRequest('/assessmentproblems/' +  assessmentProblemId + '/elements');
};

Client.prototype.createAssessmentProblemElement = function(assessmentProblemId, elementInfo){
  return this.pagedRequest('POST', '/assessmentproblems/' +  assessmentProblemId + '/elements', elementInfo);
};

Client.prototype.addAssessmentProblemElement = function(assessmentProblemId, elementId){
  return this.pagedRequest('PUT','/assessmentproblems/' +  assessmentProblemId + '/elements/' + elementId);
};

Client.prototype.removeAssessmentProblemElement = function(assessmentProblemId, elementId){
  return this.pagedRequest('DELETE','/assessmentproblems/' +  assessmentProblemId + '/elements/' + elementId);
};

Client.prototype.getStandardsForAssessmentProblem = function(assessmentProblemId){
  return this.pagedGetRequest( '/assessmentproblems/' +  assessmentProblemId + '/standards');
};

// Merge History

Client.prototype.undoLastMergeForContent = function(contentableId, undoInfo){
  return this.pagedRequest('PUT', `/mergehistory/${contentableId}/undo`, undoInfo);
};

Client.prototype.getMergeHistoriesForContent = function(contentableId){
  return this.pagedGetRequest( `/mergehistory/${contentableId}`);
};

// Problems
Client.prototype.getLessonContentContainers = function(){
  return this.pagedGetRequest('/lessoncontentcontainers');
};

Client.prototype.createLessonContentContainer = function(lessonContentContainerInfo){
  return this.pagedRequest('POST', '/lessoncontentcontainers', lessonContentContainerInfo);
};

Client.prototype.createLessonContentContainerWithContent = function(lessonContentContainerInfo) {
  return this.pagedRequest('POST', '/lessoncontentcontainers/content', lessonContentContainerInfo);
};

Client.prototype.getLessonContentContainer = function(lessonContentContainerId){
  return this.pagedGetRequest('/lessoncontentcontainers/' + lessonContentContainerId);
};

Client.prototype.getLessonContentContainerWithCurrrentVersionByElementType = function(lessonContentContainerId, elementType){
  return this.pagedGetRequest('/lessoncontentcontainers/' + lessonContentContainerId + '/currentversion/' + elementType);
};

Client.prototype.updateLessonContentContainer = function(lessonContentContainerInfo){
  return this.pagedRequest('PUT', '/lessoncontentcontainers/' + lessonContentContainerInfo.id, lessonContentContainerInfo);
};

Client.prototype.mergeLessonContentContainers = function(mergeSpec){
  return this.pagedRequest('PUT', '/lessoncontentcontainers/merge/', mergeSpec);
};

Client.prototype.deleteLessonContentContainer = function(lessonContentContainerId){
  return this.pagedRequest('DELETE', '/lessoncontentcontainers/' + lessonContentContainerId);
};

Client.prototype.getLessonContentContainerElements = function(lessonContentContainerId){
  return this.pagedGetRequest('/lessoncontentcontainers/' +  lessonContentContainerId + '/elements');
};

Client.prototype.createLessonContentContainerElement = function(lessonContentContainerId, elementInfo){
  return this.pagedRequest('POST', '/lessoncontentcontainers/' +  lessonContentContainerId + '/elements', elementInfo);
};

Client.prototype.addLessonContentContainerElement = function(lessonContentContainerId, elementId){
  return this.pagedRequest('PUT','/lessoncontentcontainers/' +  lessonContentContainerId + '/elements/' + elementId);
};

Client.prototype.removeLessonContentContainerElement = function(lessonContentContainerId, elementId){
  return this.pagedRequest('DELETE','/lessoncontentcontainers/' +  lessonContentContainerId + '/elements/' + elementId);
};

Client.prototype.getAssociatedLessonsForlessonContentContainer = function(lessonContentContainerId) {
  return this.pagedGetRequest('/lessoncontentcontainers/' + lessonContentContainerId + '/associatedlessons');
};

Client.prototype.getAssociatedlessonContentContainersForlessonContentContainer = function(lessonContentContainerId) {
  return this.pagedGetRequest('/lessoncontentcontainers/' + lessonContentContainerId + '/associatedproblems');
};

Client.prototype.getLessonContentContainerReferenceForEditorByElementType = function(lessonContentContainerId, elementType) {
  return this.pagedGetRequest('/lessoncontentcontainers/' + lessonContentContainerId + '/editorreference/' + elementType);
};

// Elements
Client.prototype.getElements = function(){
  return this.pagedGetRequest('/elements');
};

Client.prototype.createElement = function(elementInfo){
  return this.pagedRequest('POST', '/elements', elementInfo);
};

Client.prototype.getElement = function(elementId){
  return this.pagedGetRequest('/elements/' + elementId);
};

Client.prototype.getParentsForElement = function(elementId){
  return this.pagedGetRequest('/elements/' + elementId + '/parents');
};

Client.prototype.updateElement = function(elementInfo){
  return this.pagedRequest('PUT', '/elements/' + elementInfo.id, elementInfo);
};

Client.prototype.deleteElement = function(elementId){
  return this.pagedRequest('DELETE', '/elements/' + elementId);
};

Client.prototype.getAssociatedLessonsForElement = function(elementId) {
  return this.pagedGetRequest('/elements/' + elementId + '/associatedlessons');
};

Client.prototype.getAssociatedProblemsForElement = function(elementId) {
  return this.pagedGetRequest('/elements/' + elementId + '/associatedproblems');
};

Client.prototype.getElementVersions = function(elementId){
  return this.pagedGetRequest('/elements/' + elementId + '/contentversions');
};

Client.prototype.createElementVersion = function(elementId, contentVersionInfo){
  return this.pagedRequest('POST', '/elements/' + elementId + '/contentversions', contentVersionInfo);
};

Client.prototype.removeContentVersionFromElement = function(elementId, contentVersionId){
  return this.pagedRequest('DELETE', '/elements/' + elementId + '/contentversions/' + contentVersionId);
};

Client.prototype.getElementCurrentVersion = function(elementId){
  return this.pagedGetRequest('/elements/' + elementId + '/currentversion');
};

Client.prototype.createElementCurrentVersion = function(elementId, contentVersionInfo){
  return this.pagedRequest('POST', '/elements/' + elementId + '/currentversion', contentVersionInfo);
};

Client.prototype.createEbooksLessonCurrentVersionAndSplitOutProblems = function(lessonId, elementId, contentVersionInfo){
  return this.pagedRequest('POST', '/util/ebooks/import/lesson/' + lessonId + '/' + elementId , contentVersionInfo);
};

Client.prototype.simulateEbooksSync = function(bookName, lessonName, typeName, typeId, body){
  return this.pagedRequest('POST', '/util/ebooks/import?bookName=' +bookName + '&lessonName=' + lessonName + '&typeName=' + typeName + '&typeId=' + typeId, body);
};

Client.prototype.createEbooksLessonAnswersAndSplitOutProblemAnswers = function(lessonId, elementId, contentVersionInfo){
    return this.pagedRequest('POST', '/util/ebooks/import/lesson/answers/' + lessonId + '/' + elementId , contentVersionInfo);
};

Client.prototype.getClassificationStandardsForElementPublishedVersion = function(elementId){
  return this.pagedGetRequest('/elements/' + elementId + '/standards');
};

Client.prototype.removeClassificationStandardFromElementPublishedVersion = function(elementId, standardId){
  return this.pagedRequest('DELETE', '/elements/' + elementId + '/standards/' + standardId);
};

Client.prototype.addClassificationStandardToElementPublishedVersion = function(elementId, classificationStandardId) {
  return this.pagedRequest('POST', '/elements/' + elementId + '/standards/' + classificationStandardId);
};

Client.prototype.setElementCurrentVersion = function(elementId, contentVersionId){
  return this.pagedRequest('PUT', '/elements/' + elementId + '/currentversion/' + contentVersionId);
};

// TODO WBD change to put
Client.prototype.publishElementCurrentVersion = function(elementId, contentVersionId, approvalStatusInfo) {
  return this.pagedRequest('POST', '/elements/' + elementId + '/publishedversion/' + contentVersionId, approvalStatusInfo);
};

Client.prototype.findElementCurrentVersions = function(){
  return this.pagedGetRequest('/find/elements');
};

Client.prototype.searchElements = function(){
	return this.pagedGetRequest('/search/elements');
};

Client.prototype.searchElementsForUnicodeAndGetCSV = function(query){
  return this.pagedRequest('POST','/elements/search/csv', {query}).setResponseType('blob');
};

Client.prototype.syncElasticSearch = function(){
	return this.pagedRequest('POST', '/util/elasticsearch/sync', {});
};

// Element Content Types
Client.prototype.getElementTypes = function(){
  return this.pagedGetRequest('/elementtypes');
};

// Content versions
Client.prototype.getContentVersions = function(){
  return this.pagedGetRequest('/contentversions');
};

Client.prototype.createContentVersion = function(contentVersionInfo){
  return this.pagedRequest('POST', '/contentversions', contentVersionInfo);
};

Client.prototype.getContentVersion = function(contentVersionId){
  return this.pagedGetRequest('/contentversions/' + contentVersionId);
};

Client.prototype.renderContentVersion = function(contentVersionId){
  return this.pagedGetRequest('/contentversions/' + contentVersionId).setQueryParams({render: 'true'});
};

Client.prototype.getMediaForContentVersion = function(contentVersionId){
  return this.pagedGetRequest('/contentversions/' + contentVersionId + '/media');
};

Client.prototype.updateContentVersion = function(contentVersionInfo){
  return this.pagedRequest('PUT', '/contentversions/' + contentVersionInfo.id, contentVersionInfo);
};

Client.prototype.deleteContentVersion = function(contentVersionId){
  return this.pagedRequest('DELETE', '/contentversions/' + contentVersionId);
};

Client.prototype.updateMediaRelationsForAllContentVersions = function(){
  return this.pagedRequest('POST', '/util/contentversions/updatemediarelations');
};

Client.prototype.findSimilarContentVersions = function(contentVersionId){
  return this.pagedGetRequest(`/contentversions/${contentVersionId}/similar`);
};


// Content versions mapped to classification standards
Client.prototype.getClassificationStandardsForContentVersion = function(contentVersionId) {
  return this.pagedGetRequest('/contentversions/' + contentVersionId + '/classificationstandards');
};

Client.prototype.createContentStandardRelation = function(contentVersionId, classificationStandardId) {
  return this.pagedRequest('PUT', '/contentversions/' + contentVersionId + '/classificationstandards/' + classificationStandardId);
};

Client.prototype.deleteContentStandardRelation = function(contentVersionId, classificationStandardId) {
  return this.pagedRequest('DELETE', '/contentversions/' + contentVersionId + '/classificationstandards/' + classificationStandardId);
};

Client.prototype.associateElementCurrentVersionWithClassificationStandard = function(elementId, classificationStandardId) {
  return this.pagedRequest('POST', '/elements/' + elementId + '/standards/' + classificationStandardId);
};

// Content Versions WCAG validation
Client.prototype.validateContentVersion = function(contentVersionId) {
  return this.pagedGetRequest('/contentversions/' + contentVersionId + '/validation');
};

// Content Versions mapped to Approval Statuses
Client.prototype.createApprovalStatusForContentVersion = function(contentVersionId, approvalStatusInfo) {
  return this.pagedRequest('POST', '/contentversions/' + contentVersionId + '/approvalstatus', approvalStatusInfo);
};

Client.prototype.requestApprovalForContentVersion = function(contentVersionId, approvalStatusInfo) {
  return this.pagedRequest('POST', '/contentversions/' + contentVersionId + '/requestapproval', approvalStatusInfo);
};

Client.prototype.getApprovalStatusForContentVersion = function(contentVersionId) {
  return this.pagedGetRequest('/contentversions/' + contentVersionId + '/approvalstatus');
};

Client.prototype.updateApprovalStatusForContentVersion = function(approvalStatusInfo) {
  return this.pagedRequest('PUT', '/contentversions/' + approvalStatusInfo.contentVersionId + '/approvalstatus', approvalStatusInfo);
};

Client.prototype.deleteApprovalStatusForContentVersion = function(contentVersionId) {
  return this.pagedRequest('DELETE', '/contentversions/' + contentVersionId + '/approvalstatus');
};

// Content Versions mapped to Control Requirement Statuses
Client.prototype.getControlRequirementStatusesForContentVersion = function(contentVersionId) {
  return this.pagedGetRequest('/contentversions/' + contentVersionId + '/controlrequirementstatuses');
};

// Approval Statuses
Client.prototype.createApprovalStatus = function(approvalStatusInfo){
  return this.pagedRequest('POST', '/approvalstatuses', approvalStatusInfo);
};

Client.prototype.getApprovalStatuses = function(){
  return this.pagedGetRequest('/approvalstatuses');
};

Client.prototype.getApprovalStatus = function(approvalStatusId){
  return this.pagedGetRequest('/approvalstatuses/' + approvalStatusId);
};

Client.prototype.updateApprovalStatus = function(approvalStatusInfo){
  return this.pagedRequest('PUT', '/approvalstatuses/' + approvalStatusInfo.id, approvalStatusInfo);
};

Client.prototype.deleteApprovalStatus = function(approvalStatusId){
  return this.pagedRequest('DELETE', '/approvalstatuses/' + approvalStatusId);
};

// Control Requirement Statuses
Client.prototype.createControlRequirementStatus = function(controlRequirementStatusInfo) {
  return this.pagedRequest('POST', '/controlrequirementstatuses', controlRequirementStatusInfo);
};

Client.prototype.getControlRequirementStatuses = function() {
  return this.pagedGetRequest('/controlrequirementstatuses');
};

Client.prototype.getControlRequirementStatus = function(controlRequirementStatusId) {
  return this.pagedGetRequest('/controlrequirementstatuses', controlRequirementStatusId);
};

Client.prototype.updateControlRequirementStatus = function(controlRequirementStatusInfo) {
  return this.pagedRequest('PUT', '/controlrequirementstatuses/' + controlRequirementStatusInfo.id, controlRequirementStatusInfo);
};

Client.prototype.deleteControlRequirementStatus = function(controlRequirementStatusId) {
  return this.pagedRequest('DELETE', '/controlrequirementstatuses/' + controlRequirementStatusId);
};

Client.prototype.validateHtmlContent = function(html) {
  return this.pagedRequest('POST', '/controlrequirementstatuses/content', {html});
};

// Media
Client.prototype.getMedias = function(){
  return this.pagedGetRequest('/media');
};

Client.prototype.createMedia = function(mediaInfo){
  return this.pagedRequest('POST', '/media', mediaInfo);
};

Client.prototype.getMedia = function(mediaId){
  return this.pagedGetRequest('/medias/' + mediaId);
};

Client.prototype.updateMedia = function(mediaInfo){
  return this.pagedRequest('PUT', '/medias/' + mediaInfo.id, mediaInfo);
};

Client.prototype.deleteMedia = function(mediaId){
  return this.pagedRequest('DELETE', '/medias/' + mediaId);
};

Client.prototype.createMediaForExistingAsset = function(payload){
  return this.pagedRequest('POST', '/medias/', payload);
};

// Media alt text

Client.prototype.getAltTextForMedia = function(mediaId){
  return this.pagedGetRequest(`/medias/${mediaId}/alttext`);
};

Client.prototype.createAltTextForMedia = function(mediaId, mediaAltTextInfo){
  return this.pagedRequest('POST', `/medias/${mediaId}/alttext`, mediaAltTextInfo);
};

Client.prototype.updateAltTextForMedia = function(mediaId, mediaAltTextInfo){
  return this.pagedRequest('PUT', `/medias/${mediaId}/alttext/${mediaAltTextInfo.id}`, mediaAltTextInfo);
};

Client.prototype.deleteMediaAltText = function(mediaId, mediaAltTextId){
  return this.pagedRequest('DELETE', `/medias/${mediaId}/alttext/${mediaAltTextId}`);
};

// Tags

Client.prototype.getMediaForTags = function(tags, type){
  var q = `?tags=${encodeURIComponent(tags.join(','))}`;
  if (type) q += `&type=${encodeURIComponent(type)}`;

  return this.pagedGetRequest(`/medias${q}`)
};

Client.prototype.getTagsForMedia = function(mediaId){
  return this.pagedGetRequest( `/medias/${mediaId}/tags`);
};

Client.prototype.addTagsToMedia = function(mediaId, tags){
  return this.pagedRequest('PUT', `/medias/${mediaId}/tags`, {tags});
};

Client.prototype.removeTagsFromMedia = function(mediaId, tags){
  return this.pagedRequest('DELETE', `/medias/${mediaId}/tags`, {tags});
};

// EBooks Import
Client.prototype.pushEbooksLesson = function(bookName, lessonName, typeName, typeId, html){
  var q = '?bookName=' + encodeURIComponent(bookName) + '&lessonName=' + encodeURIComponent(lessonName) + '&typeName=' + encodeURIComponent(typeName) + '&typeId=' + encodeURIComponent(typeId);

  return this.pagedRequest('POST', '/util/ebooks/import' + q, {html: html} );
};


Client.prototype.findExistingLessonAndLinkContent = function (lessonId, elementId, payload) {
  return this.pagedRequest('POST', `/util/ebooks/link/lesson/${lessonId}/${elementId}`, payload);
};


// Classifications and ClassificationStandards import
Client.prototype.createClassificationsAndStandards = function(standardsJSON) {
  return this.pagedRequest('POST', '/util/classifications/import', standardsJSON);
};

// Quality Control Regulations and Control Requirements Import
Client.prototype.createQualityControlRegulationsAndControlRequirements = function(regulationsJSON) {
  return this.pagedRequest('POST', '/util/qualitycontrolrequirements/import', regulationsJSON);
};

Client.prototype.importHomeworkHelpProblem = function (problemID) {
  return this.pagedRequest('POST', `/util/homeworkhelp/import/problem/${problemID}`);
};

Client.prototype.getClassificationStandardByTruncatedShortTitle = function(){
  return this.pagedGetRequest('/util/classifications/assessments/cluster')
};

Client.prototype.getHomeworkHelpProblemResources = function (problemID) {
  return this.pagedRequest('GET', `/util/homeworkhelp/problem/${problemID}/resources`);
};

// eBooks Support

Client.prototype.renderEbooksContainerContent = function (containerId, ebooksTypeId) {
  return this.pagedRequest('GET', `/containers/${containerId}/${ebooksTypeId}/publishedversion`)
    .setQueryParams({render: 'true'});
};

Client.prototype.getGlossaryItemsForBookEbooksStyle = function (ebooksBookName, ebooksTypeId) {
  return this.pagedGetRequest(`/books/${ebooksBookName}/glossary/${ebooksTypeId}`)
};

// Homework Help Support

Client.prototype.getHomeworkHelpCategories = function () {
    return this.pagedRequest('GET', `/homework/categories`);
};

Client.prototype.getHomeworkHelpCategoryBooks = function (categoryName) {
    return this.pagedRequest('GET', `/homework/category/${categoryName}/textbooks`);
};

Client.prototype.getHomeworkHelpBook = function (categoryName, bookName) {
  return this.pagedRequest('GET', `/homework/category/${categoryName}/textbook/${bookName}`);
};

Client.prototype.getHomeworkHelpBookChapters = function (categoryName, bookName) {
    return this.pagedRequest('GET', `/homework/category/${categoryName}/textbook/${bookName}/chapters`);
};

Client.prototype.getHomeworkHelpChapterLessons = function (categoryName, bookName, chapterName) {
    return this.pagedRequest('GET', `/homework/category/${categoryName}/textbook/${bookName}/chapter/${chapterName}/lessons`);
};

Client.prototype.getHomeworkHelpLessonProblems = function (categoryName, bookName, chapterName, lessonName) {
    return this.pagedRequest('GET', `/homework/category/${categoryName}/textbook/${bookName}/chapter/${chapterName}/lesson/${lessonName}/problems`);
};

Client.prototype.getHomeworkHelpProblemHTML = function (categoryName, bookName, chapterName, lessonName, problemName) {
    return this.pagedRequest('GET', `/homework/category/${categoryName}/textbook/${bookName}/chapter/${chapterName}/lesson/${lessonName}/problem/${problemName}/html`);
};

Client.prototype.getHomeworkHelpProblem = function (categoryName, bookName, chapterName, lessonName, problemName) {
    return this.pagedRequest('GET', `/homework/category/${categoryName}/textbook/${bookName}/chapter/${chapterName}/lesson/${lessonName}/problem/${problemName}`);
};

// Assessments Support

Client.prototype.assessmentsGetClassifications = function(){
  return this.pagedGetRequest('/assessment/classifications')
};

Client.prototype.assessmentsGetBook = function(bookId){
  return this.pagedGetRequest(`/assessment/books/${bookId}`)
};

Client.prototype.assessmentsGetAssessmentProblem = function(assessmentProblemId){
  return this.pagedGetRequest(`/assessment/problems/${assessmentProblemId}`)
};

Client.prototype.assessmentsGetAssessmentProblemsByIds = function(assessmentProblemIds){
  return this.pagedRequest('POST', `/assessment/problems`, assessmentProblemIds)
};

Client.prototype.assessmentsGetClassificationViewForClassificationId = function(classifcicationId){
  return this.pagedGetRequest(`/assessment/classificationview/${classifcicationId}`)
};

Client.prototype.assessmentsGetBooksAndThreads = function(){
  return this.pagedGetRequest(`/assessment/books/threads`)
};

Client.prototype.assessmentsSearchAssessmentProblems = function(){
  return this.pagedGetRequest(`/assessment/problems/search`)
};

Client.prototype.getWcagResultsForContentVersion = function(contentVersionId){
  return this.pagedGetRequest(`/contentversions/${contentVersionId}/wcag`)
};

Client.prototype.getVersion = function(){
  return this.pagedGetRequest(`/version`)
};

Client.prototype.renderHtmlSnippet = function(payload){
  return this.pagedRequest('POST', '/util/render/html', payload)
};