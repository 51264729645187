import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Breadcrumb extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    routerInfo: PropTypes.shape({
      match: PropTypes.shape({
        params: PropTypes.shape({
          categoryShortName: PropTypes.string,
          bookName: PropTypes.string,
          chapterName: PropTypes.string,
          lessonName: PropTypes.string,
          problemName: PropTypes.string
        })
      })
    }).isRequired
  };

  static defaultProps = {
    className: null
  };

  render() {
    const { className, routerInfo } = this.props;
    if (!routerInfo || !routerInfo.match) return null;
    const {
      categoryShortName, bookName, chapterName, lessonName, problemName
    } = routerInfo.match.params;
    console.log('Breadcrumb Info here : ', routerInfo.match.params);

    const rootPath = '/';
    const categoryPath = categoryShortName ? `/category/${categoryShortName}` : null;
    const bookPath = bookName ? `${categoryPath}/textbook/${bookName}` : null;
    const chapterPath = chapterName ? `${bookPath}/chapter/${chapterName}` : null;
    const lessonPath = lessonName ? `${chapterPath}/lesson/${lessonName}` : null;

    let content = null;
    if (problemName) {
      content = (
        <React.Fragment>
          <a href={rootPath}>Home</a> ><a href={bookPath}> {bookName.toUpperCase()}</a> >
          <a href={chapterPath}> Chapter {chapterName}</a> ><a href={lessonPath}> Lesson {lessonName}</a> > Problem{' '}
          {problemName}
        </React.Fragment>
      );
    } else if (lessonName) {
      content = (
        <React.Fragment>
          <a href={rootPath}>Home</a> ><a href={bookPath}> {bookName.toUpperCase()}</a> >
          <a href={chapterPath}> Chapter {chapterName}</a> ><a href={lessonPath}> Lesson {lessonName}</a>
        </React.Fragment>
      );
    } else if (chapterName) {
      content = (
        <React.Fragment>
          <a href={rootPath}>Home</a> ><a href={bookPath}> {bookName.toUpperCase()}</a> >
          <a href={chapterPath}> Chapter {chapterName}</a>
        </React.Fragment>
      );
    } else if (bookName) {
      content = (
        <React.Fragment>
          <a href={rootPath}>Home</a> ><a href={bookPath}> {bookName.toUpperCase()}</a>
        </React.Fragment>
      );
    } else {
      content = (
        <React.Fragment>
          <a href={rootPath}>CPM Homework Home</a>
        </React.Fragment>
      );
    }

    return <h3 className={className}>{content}</h3>;
  }
}

export default Breadcrumb;
