import { App, NotFound } from '../containers';
import HWCategory from '../containers/HWCategory/Loadable';
import Book from '../containers/Book/Loadable';
import Chapter from '../containers/Chapter/Loadable';
import Lesson from '../containers/Lesson/Loadable';
import Problem from '../containers/Problem/Loadable';

const routes = [
  {
    component: App,
    routes: [
      {
        path:
          '/category/:categoryShortName/textbook/:bookName'
          + '/chapter/:chapterName/lesson/:lessonName/problem/:problemName',
        exact: true,
        component: Problem
      },
      {
        path:
          '/cpm-homework/homework/category/:categoryShortName'
          + '/textbook/:bookName/chapter/:chapterName/lesson/:lessonName/problem/:problemName',
        exact: true,
        component: Problem
      },
      {
        path: '/category/:categoryShortName/textbook/:bookName/chapter/:chapterName/lesson/:lessonName',
        exact: true,
        component: Lesson
      },
      {
        path:
          '/cpm-homework/homework/category/:categoryShortName'
          + '/textbook/:bookName/chapter/:chapterName/lesson/:lessonName',
        exact: true,
        component: Lesson
      },
      { path: '/category/:categoryShortName/textbook/:bookName/chapter/:chapterName', exact: true, component: Chapter },
      {
        path: '/cpm-homework/homework/category/:categoryShortName/textbook/:bookName/chapter/:chapterName',
        exact: true,
        component: Chapter
      },
      { path: '/category/:categoryShortName/textbook/:bookName', exact: true, component: Book },
      { path: '/cpm-homework/homework/category/:categoryShortName/textbook/:bookName', exact: true, component: Book },
      { path: '/categories', exact: true, component: HWCategory },
      { path: '/cpm-homework/homework', exact: true, component: HWCategory },
      { path: '/cpm-homework', exact: true, component: HWCategory },
      { path: '/', exact: true, component: HWCategory },
      /*
      { path: '/:categoryShortName/:bookName/:chapterName/:lessonName/:problemName', exact: true, component: Problem },
      { path: '/:categoryShortName/:bookName/:chapterName/:lessonName', exact: true, component: Lesson },
      { path: '/:categoryShortName/:bookName/:chapterName', exact: true, component: Chapter },
      { path: '/:categoryShortName/:bookName', exact: true, component: Book },
      */
      { component: NotFound }
    ]
  }
];

export default routes;
