import apiClient from '../../helpers/apiClient';

const LOAD = 'hw-help/book/LOAD';
const LOAD_SUCCESS = 'hw-help/book/LOAD_SUCCESS';
const LOAD_FAIL = 'hw-help/book/LOAD_FAIL';
const LOAD_CHAPTERS = 'hw-help/book/LOAD_CHAPTERS';
const LOAD_CHAPTERS_SUCCESS = 'hw-help/book/LOAD_CHAPTERS_SUCCESS';
const LOAD_CHAPTERS_FAIL = 'hw-help/book/LOAD_CHAPTERS_FAIL';
const c3poClient = apiClient();

const initialState = {
  loadedChapters: false,
  loaded: false,
  chapters: [],
  book: {}
};

export default function reducer(state = initialState, action = {}) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        loaded: true,
        book: action.result
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        loaded: false,
        error: action.error
      };
    case LOAD_CHAPTERS:
      return {
        ...state,
        loading: true
      };
    case LOAD_CHAPTERS_SUCCESS:
      return {
        ...state,
        loading: false,
        loadedChapters: true,
        chapters: action.result
      };
    case LOAD_CHAPTERS_FAIL:
      return {
        ...state,
        loading: false,
        loadedChapters: false,
        error: action.error
      };
    default:
      return state;
  }
}

export function getBook(catShortName, bookName) {
  const apiPromise = new Promise((resolve, reject) => {
    c3poClient.getHomeworkHelpBook(catShortName, bookName).exec((err, response) => {
      if (err) reject(err);
      else {
        resolve(response);
      }
    });
  });

  return {
    types: [LOAD, LOAD_SUCCESS, LOAD_FAIL],
    promise: async () => apiPromise
  };
}

export function getBookChapters(catShortName, bookName) {
  const apiPromise = new Promise((resolve, reject) => {
    c3poClient.getHomeworkHelpBookChapters(catShortName, bookName).exec((err, response) => {
      if (err) {
        console.log('Book Reducer Error ', err);
        reject(err.message);
      } else {
        resolve(response);
      }
    });
  });

  return {
    types: [LOAD_CHAPTERS, LOAD_CHAPTERS_SUCCESS, LOAD_CHAPTERS_FAIL],
    promise: async () => apiPromise
  };
}
