import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { renderRoutes } from 'react-router-config';
import Helmet from 'react-helmet';
import config from 'config';
import classnames from 'classnames';

import Navbar from 'components/Navbar/Navbar';
import { setUserDiscovered } from '../../redux/modules/auth';

function parseJwt(token) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace('-', '+').replace('_', '/');
  return JSON.parse(window.atob(base64));
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts
      .pop()
      .split(';')
      .shift();
  }
}

@connect(
  state => ({
    existingJWT: state.auth.jwt
  }),
  dispatch => ({
    pushState: push,
    mappedSetUserDiscovered: payload => dispatch(setUserDiscovered(payload))
  })
)
@withRouter
class App extends Component {
  static propTypes = {
    route: PropTypes.objectOf(PropTypes.any).isRequired,
    location: PropTypes.objectOf(PropTypes.any).isRequired,
    existingJWT: PropTypes.string,
    pushState: PropTypes.func.isRequired,
    mappedSetUserDiscovered: PropTypes.func.isRequired
  };

  static contextTypes = {
    store: PropTypes.objectOf(PropTypes.any).isRequired
  };

  static defaultProps = {
    existingJWT: null
  };

  componentDidMount() {
    const jssStyles = document.getElementById('jss-server-side');
    console.log('App.didMount()', jssStyles);
    if (jssStyles && jssStyles.parentNode) {
      jssStyles.parentNode.removeChild(jssStyles);
    }
    this.checkAuthentication();
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props;

    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }

  checkAuthentication() {
    const { existingJWT, mappedSetUserDiscovered } = this.props;
    const ssoCookie = getCookie('cpm-sso-token');

    if (ssoCookie) {
      const decodedJWT = parseJwt(ssoCookie);
      if (existingJWT !== ssoCookie) {
        mappedSetUserDiscovered({ jwt: ssoCookie, decodedJWT });
      }
    }
  }

  render() {
    const { route } = this.props;
    const styles = require('./App.scss');
    return (
      <div className={styles.app}>
        <Helmet {...config.app.head} />

        <Navbar />

        <div className={styles.appContent}>
          <div>{renderRoutes(route.routes)}</div>
        </div>

        <div className={classnames(styles.footer, 'text-center', 'footer')}>
          <p>© 2022 CPM Educational Program. All rights reserved.</p>
        </div>
      </div>
    );
  }
}

export default App;
